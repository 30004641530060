import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import { BiLoaderAlt } from 'react-icons/bi'; 

//images
import placeholder_img from '../images/placeholder.svg';


class Nyhet extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            data: [],
            is_loaded: false, 
        };
    }

    componentDidMount(){
        const news_id = this.props.location.hash.substring(1);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: news_id })
        };

        fetch('https://excendo.hamraz.se/admin/api/news_by_id.php', requestOptions)
        .then(res => res.json())
        .then( json => {
            this.setState({
                is_loaded: true, 
                data: json,       
            })
        })
    }

    render(){
        const { is_loaded, data} = this.state;
        return(
            <Layout>
                <Helmet>
                    <title>Excendo Redovisning AB</title>
                    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <meta property="og:title" content="Excendo Redovisning AB" />
                    <meta property="og:tyope" content="website" />
                    <meta property="og:discription" content="" />
                    <meta property="og:image" content=" " />
                    <meta property="og:locale" content="sv_SE" />
                    <meta property="og:url" content="http://www.excendo.se/" />
                    <link rel="cannonical" href="http://www.excendo.se/" />
                </Helmet>
                <Navbar whitemode={false} />
                {!is_loaded ? 
                    <section className="min-h-screen bg-gray-section-background py-16">
                        <div className="container mx-auto px-4 xl:px-24">
                            <div className="w-full flex justify-center items-center py-10">
                                <BiLoaderAlt className="animate-spin w-16 h-16 text-blue-excendo" />
                            </div>
                        </div>
                    </section> 
                :
                <>
                    <section className="w-screen bg-contact bg-center bg-no-repeat bg-cover -mt-20 pt-20 ">
                        <div className="container mx-auto  px-4 xl:px-24 pt-10">
                            <div className="grid grid-cols-6">
                                {data.map(i => 
                                    <div className="col-span-6 lg:col-span-5 w-full  mx-auto  flex flex-col ">
                                        <div className="w-full " >
                                            <img
                                                className="h-full w-full object-cover object-center block rounded-t-md" 
                                                alt="bild"
                                                src={i.image_size == 0 ? placeholder_img : i.image_url}  />
                                        </div>
                                        <div className="w-full h-full flex flex-col justify-between px-6 pt-6 pb-20 overflow-hidden" style={{background: "rgba(255, 255, 255, 0.7)"}}>                   
                                            <p className="font-semibold text-gray-excendo">{i.created_at.substring(0,10)}</p>
                                            <h1 className="my-4 font-bold text-black-excendo text-2xl lg:text-3xl">{i.title}</h1>
                                            <div className="text-black-excendo" dangerouslySetInnerHTML={{ __html: i.body}} />
                                        </div>
                                    </div>
                                    
                                )}

                                <div className="hidden lg:col-span-1  lg:flex flex-col items-end justify-start">
                                    <a 
                                        href="https://www.facebook.com/ExcendoRedovisning" 
                                        target="_blank"
                                        className="bg-white rounded-full h-16 w-16 text-black-excendo flex items-center justify-center"
                                    >
                                            <svg className="w-6 h-6 fill-current" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                            </svg>

                                    </a>
                                    <a 
                                        href="https://www.instagram.com/excendoredovisning/" 
                                        target="_blank"
                                        className="bg-white rounded-full h-16 w-16 text-black-excendo flex items-center justify-center mt-4"
                                    >
                                        <svg className="w-7 h-7 fill-current"  viewBox="0 0 20 20">
                                            <path d="M14.52,2.469H5.482c-1.664,0-3.013,1.349-3.013,3.013v9.038c0,1.662,1.349,3.012,3.013,3.012h9.038c1.662,0,3.012-1.35,3.012-3.012V5.482C17.531,3.818,16.182,2.469,14.52,2.469 M13.012,4.729h2.26v2.259h-2.26V4.729z M10,6.988c1.664,0,3.012,1.349,3.012,3.012c0,1.664-1.348,3.013-3.012,3.013c-1.664,0-3.012-1.349-3.012-3.013C6.988,8.336,8.336,6.988,10,6.988 M16.025,14.52c0,0.831-0.676,1.506-1.506,1.506H5.482c-0.831,0-1.507-0.675-1.507-1.506V9.247h1.583C5.516,9.494,5.482,9.743,5.482,10c0,2.497,2.023,4.52,4.518,4.52c2.494,0,4.52-2.022,4.52-4.52c0-0.257-0.035-0.506-0.076-0.753h1.582V14.52z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                
                    <Footer />
                </>
                }
            </Layout>
        );
       
    }   
}
export default Nyhet;